import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.to(".scrolldown", {
  scrollTrigger: {
    trigger: ".scrolldown",
    start: "bottom 50%",
    markers: false,
  },
  opacity: 0,
  duration: 1,
});

gsap.from(".featured", {
  scrollTrigger: {
    trigger: ".scrolldown",
    start: "bottom top",
    markers: false,
  },
  opacity: 0,
  scale: 0.3,
  delay: 0.5,
  duration: 1,
});

gsap.from(".intro-text", {
  scrollTrigger: {
    trigger: ".scrolldown",
    start: "bottom top",
    markers: false,
  },
  opacity: 0,
  duration: 1
});

// gsap.to(".scrolldown", {
//   scrollTrigger: {
//     trigger: ".hero",
//     start: "top top",
//     markers: false,
//   },
//   opacity: 0,
//   delay: 1,
//   duration: 1
// });

// gsap.to(".scrolldown", {
//   scrollTrigger: {
//     trigger: ".hero",
//     start: "top top",
//     markers: false,
//   },
//   "--display": "none",
//   delay: 2
// });

// const mm = gsap.matchMedia();

// mm.add("(min-width: 800px)", () => {

//   ScrollTrigger.create({
//     trigger: ".hero",
//     start: "top top",
//     pin: ".hero",
//     markers: false,
//   });

//   gsap.from(".featured-wrapper", {
//     scrollTrigger: {
//       trigger: ".intro",
//       start: "top 50%",
//       markers: false,
//       scrub: true,
//     },
//     yPercent: 50,
//     scale: 0
//   })
// });
